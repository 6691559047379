import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';

const AudienceTag = ({ audience }) => {

  const flag = useFeatureFlag("HomePage_FrontEnd_DisplayAudience")

  if (!flag) {
    return false;
  }
  //going deliberately simple here
  const styles = {
    position: "fixed",
    bottom: "6rem",
    right: "1rem",
    zIndex: "999",
  };
  return (
    <div style={styles}>
      <Area intent={"negative"} style={{ padding: "0.25rem" }}>
        <Typography size={1} font={"bold"}>
          {audience}{" "}
        </Typography>
      </Area>
    </div>
  );
};
export default AudienceTag;
