import CalloutCard from "Clutch/Organisms/CalloutCard/CalloutCard";
import { useInView } from "react-intersection-observer";
import styles from "./CalloutSection.module.scss";

const CalloutSection = ({ leftCallout, rightCallout }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });

  if (!leftCallout && !rightCallout) return false

  return (
    <>
      <div ref={ref} />
      <div className={styles.wrapper}>
        {leftCallout && <CalloutCard {...leftCallout} className={styles.card} imageLoading={!inView} />}
        {rightCallout && <CalloutCard {...rightCallout} imageLoading={!inView} />}
      </div>
    </>
  );
};

export default CalloutSection;
