import styles from "./Maker.module.scss";
import MakerEnhance from "maker-enhance";
import ClassNames from 'classnames'

const Maker = ({ heightConfig, light = false, gradient = false }) => {
  return (
    <div className={ClassNames(`${!light ? styles.darkMaker : ''} ${styles.makerContainer} ${heightConfig ? styles.makerContainerWithConfig : ""}`)} style={heightConfig ?? {}}>
      {gradient ? <div className={styles.makerGradient}></div> : false}
      <MakerEnhance user={"speedway"} />
    </div>
  );
};

export default Maker;
