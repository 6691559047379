import RecommendationSection from "Clutch/Organisms/RecommendationSection";
import Typography from "Clutch/Atoms/Typography"
import TextLink from "Clutch/Atoms/TextLink";
import styles from "./RecBlock.module.scss"
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";




const RecBlock = ({ sectionName = "HomePage", numberOfSkeletonSections = 1 }) => {

  return (
    <div className={styles.container}>
    <TextLink targetBlank nofollow intent="action" className={styles.recommmendationLinkTop} href="/recommendations" segmentEvent={createClickedSegmentEvent("`Recommendations For You` via RecBlock")}>
      More Recommended Products
    </TextLink>
    <RecommendationSection
      section={sectionName}
      numberOfSkeletons={10}
      numberOfSkeletonSections={numberOfSkeletonSections}
      skeletonDisplayType={"Carousel"}
      displayType={"Carousel"}
      spacingSize={"SM"}
      lazyMargin={"800px"}
      leftAlignCarousel
      extendGuttersForCarousel
      /> 
      <TextLink targetBlank nofollow intent="action" className={styles.recommmendationLinkBottom} href="/recommendations" segmentEvent={createClickedSegmentEvent("`Recommendations For You` via RecBlock")}>
      More Recommended Products
    </TextLink>
      </div>
  );
};

export default RecBlock;
