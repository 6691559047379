import { useState } from "react";
import { useInView } from "react-intersection-observer";
import Image from "next/image";
import dynamic from "next/dynamic";
import useSegment from "Hooks/useSegment";
import Carousel from "Clutch/Organisms/Carousel";
import Card from "Clutch/Atoms/Card";
import SVG from "Clutch/Atoms/Svg";
import Panel from "Clutch/Atoms/Panel";
import Skeleton from "Clutch/Atoms/Skeleton";
import styles from "./UgcBlock.module.scss";
import { createUgcClickedEvent } from "../../../instrumentation/ugc/ugcSegmentEvents";
import Gutters from "Clutch/Atoms/Gutters";

const UgcModal = dynamic(() =>
  import("../../../chunks/chunk_deferred").then((module) => module.UgcModal)
);
const UgcDrawer = dynamic(() =>
  import("../../../chunks/chunk_deferred").then((module) => module.UgcDrawer)
);


const UgcBlock = ({ content, inverseTheme }) => {
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { sendCustomSegmentTrackEvent } = useSegment();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });

  const handleOnClose = () => {
    setIsPopupOpen(false);
  };

  const handleOnOpen = (post, index) => {
    setActiveContentIndex(index);
    setIsPopupOpen(true);
    const segmentEvent = createUgcClickedEvent(
      post.id,
      index,
      post.url,
      post.source,
      post.imageUrl
    );
    sendCustomSegmentTrackEvent(segmentEvent);
  };

  return (
    <>
      <div ref={ref}></div>

      <UgcModal
        containerClassName={styles.ugcModal}
        isOpen={isPopupOpen}
        onClose={handleOnClose}
        content={content}
        activeContentIndex={activeContentIndex}
        id={"ugc_modal"}
      />
      <UgcDrawer
        isOpen={isPopupOpen}
        onClose={handleOnClose}
        content={content}
        activeIndex={activeContentIndex}
        containerClassName={styles.ugcDrawer}
      />
      <Gutters>
        {inView ? (
          <>
            <Carousel extendGutters>
              {content.map((post, index) => (
                <Carousel.Item key={`ugc-${index}`} data-testid={`ugc-${index}`}>
                  <Card
                    className={`${styles.ugcCard} lr-ugcCard`}
                    fillHeight
                    noPadding
                    backgroundColorOverride={"rgba(0, 0, 0, 0.15)"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnOpen(post, index);
                    }}
                  >
                    <Panel layer={0} inverseTheme={inverseTheme}>
                      {post.contentType === "video" ? (
                        <SVG
                          icon={"video"}
                          size={2}
                          className={styles.videoIcon}
                        />
                      ) : null}
                      <Image
                        src={post.imageUrl ? post.imageUrl : post.thumbnailUrl}
                        alt={post?.imageAltText}
                        width={228}
                        height={228}
                        className={styles.ugcImage}
                      />
                    </Panel>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          </>
        ) : (
          <Skeleton height={228} fullWidth />
        )}
      </Gutters>
    </>
  );
};

export default UgcBlock;
