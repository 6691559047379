import { useInView } from "react-intersection-observer";

import Tabs from "Clutch/Components/Tabs/Tabs";
import Expander from "Clutch/Organisms/Expander/Expander";
import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack";

import styles from "./CategorySection.module.scss";

const CategorySection = ({ categories }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });
  const items = (categories || []).map((category) => {
    const subLinks = (category.subLinks || []).map((subLink) => {
      return {
        displayValue: subLink.displayName,
        url: subLink.url,
        imageUrl: subLink.imageUrl,
        noFollow: false,
      };
    });
    let categoryLabel = category.category.replace("and", "&");
    switch (category.category) {
      case "Exterior, Accessories and Trim":
        categoryLabel = "Exterior";
        break;
      case "Interior, Accessories and Trim":
        categoryLabel = "Interior";
        break;
      default:
        break;
    }
    const isAllCategoriesTab = categoryLabel === "All Categories";
    let TabContentComponent = null;
    if (isAllCategoriesTab) {
      TabContentComponent = (
        <LandingPageLinkRack
          containerClassName={styles.allCategoriesContainer}
          links={subLinks}
        />
      );
    } else {
      TabContentComponent = (
        <LandingPageLinkRack
          links={subLinks}
          showMoreLink={{ url: category.shopUrl, text: category.shopText }}
          dataTestId={category.category}
          representativeImage={
            category.representativeImage
              ? {
                url: category.representativeImage,
                altText: `${category.category} representative image`,
              }
              : null
          }
          areImagesLoading={!inView}
        />
      );
    }

    return {
      key: `category_section_${category.category}`,
      label: categoryLabel,
      content: TabContentComponent,
    };
  });

  return (
    <>
      <div ref={ref} />
      <Expander
        closedHeight={"450px"}
        buttonProps={{ intent: false, fill: false, invert: false }}
        showOnMobileOnly
        testid={"categories"}
      >
        <div className={styles.tabsContainer}>
          <div>
            <Tabs
              brand={"primary"}
              items={items}
              centered
              size={1}
              seoFocused
              useDropdown
              dataTestId={"categories"}
              className={styles.tabContent}
              selectLabel={'categories'}
            />
          </div>
        </div>
      </Expander>
    </>
  );
};

export default CategorySection;
